import {
  Bold,
  Alignment,
  DecoupledEditor as DecoupledEditorBase,
  Italic,
  Underline,
  Strikethrough,
  BlockQuote,
  Heading,
  Link,
  List,
  Paragraph,
  Essentials,
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
  TableColumnResize,
  ImageBlock,
  ImageInsert,
  ImageResize,
  ImageStyle,
  SimpleUploadAdapter,
  ImageToolbar,
  Plugin,
  EditorConfig,
} from 'ckeditor5';
import { imageUploaderAuthToken, imageUploaderUrl, ckEditorLicenseKey } from '@shared/config';

import 'ckeditor5/ckeditor5.css';

class DecoupledEditor extends DecoupledEditorBase {}

const decoupledPlugins: (typeof Plugin)[] = [
  Bold,
  Alignment,
  Italic,
  Underline,
  Strikethrough,
  BlockQuote,
  Heading,
  Link,
  List,
  Paragraph,
  Essentials,
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
  TableColumnResize,
  ImageBlock,
  ImageInsert,
  ImageToolbar,
  ImageStyle,
  ImageResize,
  SimpleUploadAdapter,
];

DecoupledEditor.builtinPlugins = decoupledPlugins;

const decoupledConfig = {
  toolbar: [
    'undo',
    'redo',
    '|',
    'heading',
    '|',
    'alignment',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'link',
    'blockQuote',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'insertImage',
    'insertTable',
    '|',
    'comment',
    'trackChanges',
  ],
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h4', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h5', title: 'Heading 4', class: 'ck-heading_heading4' },
      { model: 'heading5', view: 'h6', title: 'Heading 5', class: 'ck-heading_heading5' },
    ],
  },
  placeholder: 'Write your content here…',
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties'],
  },
  image: {
    upload: { types: ['jpeg', 'png', 'gif', 'bmp'] },
    styles: {
      options: ['block', 'alignBlockLeft', 'alignBlockRight', 'alignLeft', 'alignRight'],
    },
    toolbar: [
      'imageStyle:alignLeft',
      'imageStyle:alignRight',
      '|',
      'imageStyle:alignBlockLeft',
      'imageStyle:block',
      'imageStyle:alignBlockRight',
    ],
  },
  simpleUpload: {
    uploadUrl: imageUploaderUrl,
    headers: {
      Authorization: `Bearer ${imageUploaderAuthToken}`,
    },
  },
  licenseKey: ckEditorLicenseKey,
} as EditorConfig;

DecoupledEditor.defaultConfig = decoupledConfig;

export default DecoupledEditor;
