import { CKEditorContext } from '@ckeditor/ckeditor5-react';
import { Context, Writer, Plugin, CloudServices, EditorConfig, ContextWatchdog } from 'ckeditor5';
import {
  Annotations,
  RealTimeCollaborativeEditing,
  Comments,
  CommentsRepository,
  PresenceList,
  RealTimeCollaborativeComments,
  RealTimeCollaborativeTrackChanges,
  InlineAnnotations,
  TrackChanges,
} from 'ckeditor5-premium-features';
import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { appDataModel } from '@entities/app-data';
import { authUserModel } from '@entities/auth-user';
import { getCKEditorToken } from '@shared/api/cloud-functions';
import { ckEditorBundleVersion, csUploadUrl, csWebSocketUrl, extraFields, isDevelopment } from '@shared/config';
import { CKEditorInstance } from '@shared/types';
import { Loader } from '@shared/ui';
import { getPresenceListContainer } from '@widgets/header-editor';
import editors from '../config';
import { EditorBase } from './decoupled-editor';
import { ExtraFields } from 'blooksy-backend';
import { docModel } from '@entities/doc';
import { appendixModel } from '@entities/appendix';
import { debounce } from 'lodash';
import { extractHeadings, extractTableOrImgTitles } from '../lib';

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

const cloudServicesConfig = {
  uploadUrl: csUploadUrl,
  webSocketUrl: csWebSocketUrl,
};

type CKEditorProps = {
  bookId: string;
  documentHash: string;
  onReady?: (editor: CKEditorInstance) => void;
  onError?: (error: Error) => void;
  config?: EditorConfig;
  example?: ReactNode;
  section?: 'content' | 'appendix';
};

class MyPresenceList extends PresenceList {
  static initializeCount = 0;

  constructor(context: Context) {
    super(context);

    MyPresenceList.initializeCount += 1;
  }

  init() {
    MyPresenceList.initializeCount -= 1;
    const presenceList = getPresenceListContainer();
    // in strict mode we have to many rerender and this fix not working
    if (MyPresenceList.initializeCount > 0 && presenceList && !isDevelopment) {
      presenceList.innerHTML = '';
    }
  }
}
class TimeoutFixPlugin extends Plugin {
  static get requires() {
    return ['WebSocketGateway'];
  }
  init() {
    const WSG = this.editor.plugins.get('WebSocketGateway');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    WSG.connection._options.requestTimeout = 1000 * 60; // 1 minute.
  }
}

const DEBOUNCE_SAVE_TOC_DATA_TIME = 1000;

export const CKEditorCS: FC<CKEditorProps> = ({ bookId, documentHash, onReady, onError, config, example, section }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [presenceList, setPresenceList] = useState<HTMLDivElement | null>(null);
  const users = useSelector(appDataModel.selectors.selectBookUsersWithError);
  const currentBook = useSelector(appDataModel.selectors.selectedBookWithError);
  const user = useSelector(authUserModel.selectors.selectUserWithError);

  const isApaPaper = currentBook.type === 'apa';

  const bookUser = users.find(i => i.id === user.uid);
  if (!bookUser) {
    throw new Error('book user not found');
  }

  const documentId = `${bookId}.${documentHash}`;

  useEffect(() => {
    setIsLoading(true);
  }, [documentId]);

  useEffect(() => {
    setPresenceList(getPresenceListContainer());
  }, []);

  const saveTocData = useCallback(
    (editor: CKEditorInstance) => {
      if (section === 'content') {
        const headings = extractHeadings(editor.getData());
        dispatch(
          docModel.actions.saveDocDetailsAction({
            content: headings,
            bookId,
            id: documentHash,
            fieldName: 'headings',
          })
        );

        if (isApaPaper) {
          const tableTitles = extractTableOrImgTitles('table', editor.getData());
          dispatch(
            docModel.actions.saveDocDetailsAction({
              content: tableTitles,
              bookId,
              id: documentHash,
              fieldName: 'tableTitles',
            })
          );

          const imageTitles = extractTableOrImgTitles('img', editor.getData());
          dispatch(
            docModel.actions.saveDocDetailsAction({
              content: imageTitles,
              bookId,
              id: documentHash,
              fieldName: 'imageTitles',
            })
          );
        }
      }

      if (section === 'appendix' && isApaPaper) {
        const tableTitles = extractTableOrImgTitles('table', editor.getData());
        dispatch(
          appendixModel.actions.saveAppendixDetailsAction({
            content: tableTitles,
            bookId,
            id: documentHash,
            fieldName: 'tableTitles',
          })
        );

        const imageTitles = extractTableOrImgTitles('img', editor.getData());
        dispatch(
          appendixModel.actions.saveAppendixDetailsAction({
            content: imageTitles,
            bookId,
            id: documentHash,
            fieldName: 'imageTitles',
          })
        );
      }
    },
    [bookId, dispatch, documentHash, section, isApaPaper]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveTocData = useCallback(debounce(saveTocData, DEBOUNCE_SAVE_TOC_DATA_TIME), [saveTocData]);

  const editorRef = useRef<CKEditorInstance>();

  useEffect(() => {
    return () => {
      editorRef.current = null;
    };
  }, []);

  const focusAtTheEndOfEditor = () => {
    if (!editorRef.current) return;
    editorRef.current.editing.view.focus();
    editorRef.current.model.change((writer: Writer) => {
      writer.setSelection(writer.createPositionAt(editorRef.current.model.document.getRoot(), 'end'));
    });
  };

  const onEditorWrapperClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  if (!presenceList) {
    return <Loader isFullScreen={false} />;
  }

  return (
    <div
      className={classNames(
        'flex-grow pb-80',
        !isLoading ? 'cursor-text' : '',
        extraFields.includes(documentHash as ExtraFields) && !isApaPaper ? '' : currentBook.type,
        documentHash
      )}
      onClick={focusAtTheEndOfEditor}
    >
      {isLoading && (
        <div className='absolute top-0 flex items-center justify-center left-0 w-full h-full bg-neutrals-0'>
          <Loader isFullScreen={false} size='sm' />
        </div>
      )}
      <div onClick={onEditorWrapperClick} className='w-full h-full'>
        <CKEditorContext
          key={`${documentId}.${users.length > 1 ? 'collaboration' : 'single'}.${bookUser.role}`}
          context={Context}
          contextWatchdog={ContextWatchdog}
          config={{
            plugins: [CloudServices, ...(users.length > 1 ? [CommentsRepository, MyPresenceList] : [])],
            cloudServices: {
              tokenUrl: async () => {
                const result = await getCKEditorToken(bookId);
                return result.data;
              },
              uploadUrl: cloudServicesConfig.uploadUrl,
              webSocketUrl: cloudServicesConfig.webSocketUrl,
              documentId,
              bundleVersion: ckEditorBundleVersion,
            },
            collaboration: {
              channelId: documentId,
            },
            presenceList: {
              container: presenceList,
            },
          }}
        >
          <EditorBase
            documentId={`${bookId}.${documentHash}`}
            config={{
              plugins: [
                ...(editors.DecoupledEditor.builtinPlugins ?? []),
                RealTimeCollaborativeEditing,
                Comments,
                RealTimeCollaborativeComments,
                RealTimeCollaborativeTrackChanges,
                InlineAnnotations,
                Annotations,
                TrackChanges,
                TimeoutFixPlugin,
              ],

              collaboration: {
                channelId: documentId,
              },
              ...config,
            }}
            onReady={editor => {
              editorRef.current = editor;
              onReady?.(editor);
              setIsLoading(false);
              if (bookUser.role === 'reader' || bookUser.role === 'commenter') {
                editor.plugins.get('CommentsOnly').isEnabled = true;
              }

              editor.on('destroy', () => {
                const webSocket = editor.plugins.get('WebSocketGateway');
                presenceList.innerHTML = '';
                webSocket.connection._socket.close();
              });

              if (bookUser.role === 'owner' || bookUser.role === 'writer') {
                if ((editor.getData() as string).trim() === '') {
                  editor.data.set(example ?? '', { suppressErrorInCollaboration: true });
                }
                if (section) debouncedSaveTocData(editor);
              }
            }}
            onError={onError}
            onChange={(_, editor) => {
              if (section) debouncedSaveTocData(editor);
            }}
          />
        </CKEditorContext>
      </div>
    </div>
  );
};
