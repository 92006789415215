import {
  Paragraph,
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Link,
  List,
  Essentials,
  BalloonEditor as BalloonEditorBase,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

class BalloonEditor extends BalloonEditorBase {}

const balloonPlugins = [Paragraph, Bold, Italic, Underline, Strikethrough, Link, List, Essentials];

BalloonEditor.builtinPlugins = balloonPlugins;

const balloonConfig = {
  toolbar: [
    'undo',
    'redo',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'link',
    '|',
    'bulletedList',
    'numberedList',
  ],
  placeholder: 'Write your notes here…',
};

BalloonEditor.defaultConfig = balloonConfig;

export default BalloonEditor;
