import * as yup from 'yup';

export const DEFAULT_CREATIVITY = 1.0;

// All prompt length = 1000 characters. 100 characters - template on cloud function.
export const TOPIC_MAX_LENGTH = 100;
export const KEYWORDS_MAX_LENGTH = 100;
export const DESCRIPTION_MAX_LENGTH = 5000;

export const CURRENT_AI_TOOL_NAME = 'Generate Content';

type CreditPlan = {
  id: number;
  label: string;
  text: string;
  value: AvailableCoinsCount;
  price: number;
};

export type AvailableCoinsCount = 10 | 30 | 50;

export const coinsPlans: CreditPlan[] = [
  { id: 1, label: '10 uses', text: 'I want Aven to write 50-90 paragraphs', value: 10, price: 10 },
  { id: 2, label: '30 uses', text: 'I want Aven to write 91-210 paragraphs', value: 30, price: 25 },
  { id: 3, label: '50 uses', text: 'I want Aven to write 211-500 paragraphs', value: 50, price: 39 },
];

export const createTextValidationSchema = yup.object({
  topic: yup
    .string()
    .trim()
    .required('Topic is required.')
    .max(TOPIC_MAX_LENGTH, `Topic can't be longer than ${TOPIC_MAX_LENGTH} characters.`),
  description: yup
    .string()
    .trim()
    .required('Topic description is required.')
    .max(DESCRIPTION_MAX_LENGTH, `Topic description can't be longer than ${DESCRIPTION_MAX_LENGTH} characters`),
  keywords: yup.string().max(KEYWORDS_MAX_LENGTH, `Keywords can't be longer than ${KEYWORDS_MAX_LENGTH} characters`),
});
export type CreateTextFormData = yup.InferType<typeof createTextValidationSchema>;
