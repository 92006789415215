import { Location } from 'history';
import { buffers, eventChannel } from 'redux-saga';
import { cancelled, CancelledEffect, takeLatest } from 'redux-saga/effects';

import { history } from '@entities/history';
import { isDevelopment } from '@shared/config';
import { logger, blocker } from '@shared/lib';
import { Saga } from '@shared/types';

interface LocationPayload {
  pathname: string;
  search: string;
  hash: string;
  state: unknown;
}

export function* onHistory(sagaWorker: Saga, historyName: string) {
  const historyChannel = eventChannel(emitter => {
    let location = history.location;
    emitter({ ...location });

    const unsubscribe = history.listen((newLocation: Location<string>) => {
      if (location.pathname !== newLocation.pathname) {
        location = newLocation;
        emitter({ ...location });
      }
    });
    return () => {
      unsubscribe();
    };
  }, buffers.expanding<LocationPayload>());

  try {
    yield takeLatest(historyChannel, sagaWorker);
    yield blocker();
  } finally {
    const isCancel: CancelledEffect = yield cancelled();

    if (isCancel) {
      isDevelopment && logger.info(`History '${historyName}' has been canceled!`);
      historyChannel.close();
    }
  }
}
